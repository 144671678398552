<template>
<div class="autonomous">
<!--  顶部导航-->
  <div class="dh">
    <div class="navigation">
      <div class="ico" @click="go()">
        <img class="icon" src="../assets/img15.png">
        <p class="designation">华钛信息</p>
      </div>
      <div class="selectivity">
        <div class="alternative">
          <div class="xzx1 xzxz1">
            <span class="xzxz" @click="hang()">行业方案<span class="xzl"></span></span>
            <div class="nav1 nav">
              <div class="navxx">
                <span>行业方案</span>
                <span>华钛信息致力于帮助各类型企业搭建贴合业务的数据平台目前服务的客户涵盖金融、互联网、制造业、零售业、汽车等多个行业。</span>
                <span @click="hang()">前往行业方案 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzData" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img188.png">
              </div>
            </div>
          </div>
          <div class="xzx2 xzxz1">
            <span class="xzxz" @click="zi()">自主产品 <span class="xzl"></span></span>
            <div class="nav2 nav">
              <div class="navxx">
                <span>自主产品</span>
                <span>华钛信息供应商采购平台以为企业降本增效、提高经营效率、提升数据价值为导向，含供应商管理、寻源管理、采购协同、财务协同等模块。</span>
                <span @click="zi()">前往自主产品 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzDatas" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img189.png">
              </div>
            </div>
          </div>
          <div class="xzx3 xzxz1">
            <span class="xzxz" @click="fu()">服务范围 <span class="xzl"></span></span>
            <div class="nav3 nav">
              <div class="navxx">
                <span>服务范围</span>
                <span>华钛信息以合作共赢的服务理念为核心，为企业提供优质化服务。</span>
                <span @click="fu()">前往服务范围 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzDataes" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img190.png">
              </div>
            </div>
          </div>
          <div class="xzx4 xzxz1" v-for="(item ,index) in newData" :key="index">
            <span class="xzxz" @click="din(item)">{{item.cname}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  第一层-->
  <div class="s-top"></div>
<!--第二层-->
  <div class="a-second">
    <div class="group3">
      <ul>
        <li @click="cur=0">
          <p :class="{ active: cur == 0 }">SRM系统</p>
          <p :class="{ underline: cur == 0 }"></p>
        </li>
        <li @click="cur=1">
          <p :class="{ active: cur == 1 }">PAC系统</p>
          <p :class="{ underline: cur == 1 }"></p>
        </li>
      </ul>
    </div>
    <div class="group3-x" v-show="cur==0">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../assets/img3.png">
            <span>产品介绍</span>
            <img class="img4" src="../assets/img4.png">
          </div>
        </div>
      </div>
      <div class="nei">以采购管控为核心,协同管理为辅助,数据分析为支撑,搭建企业标准采购数字化平台,实现供应链上下游的数据互联互通,加强成本控制,提高采购管理效率.以采购管控为核心,协同管理为辅助,数据分析为支撑,搭建企业标准采购数字化平台,实现供应链上下游的数据互联互通,加强成本控制,提高采购管理效率.

      </div>
    </div>
  </div>
<!--  第三层-->
  <div class="a-thirdy">
    <img src="../assets/img40.png">
  </div>
<!--  第四层-->
  <div class="a-four">
    <div class="spreadhead">
      <div class="subhead">
        <div class="moit">
          <img class="img3" src="../assets/img3.png">
          <span>自主产品</span>
          <img class="img4" src="../assets/img4.png">
        </div>
      </div>
    </div>
    <div class="factory">
      <img src="../assets/img41.png">
    </div>
  </div>
  <!--    底层-->
  <div class="ground-floor">
    <div class="fbox">
      <div class="fbox1">
        <div class="fbox2">
          <div class="lianxi">
            <div class="fico">
              <img class="ficon" src="../assets/img2.png">
              <p class="fdesignation">华钛信息</p>
            </div>
            <div class="contway">
              <div class="special">
                <span>联系电话：</span>
                <span>021-55962658</span>
              </div>
              <div class="special">
                <span>商务合作：</span>
                <span>business@huataish.com</span>
              </div>
            </div>
          </div>
          <div class="cut-line"></div>
          <div class="explain">
            <div class="explain1">
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">行业方案</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatas" :key="index">
                      <span @click="din(item)">{{ item.lei }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">自主产品</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatass" :key="index">
                      <span @click="din(item)">{{ item.lei1 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">服务范围</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatasss" :key="index">
                      <span @click="din(item)">{{ item.lei2 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="cl()">客户案例</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                      <span>{{ item.lei3 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="hz()">合作伙伴</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                      <span>{{ item.lei3 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="xx()">华钛信息</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataess" :key="index">
                      <span @click="din(item)">{{ item.lei4 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cut-line1"></div>
        <div class="safety">
          <span>上海总部: 上海市长宁区凯旋路1058号1-2号楼2楼</span>
          <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20008244号-1</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Autonomous",
  data() {
    return {
      cur: 0,
      hzData:[
        {
          hzz:"金融行业",
          url:'/FinAnce'
        },
        {
          hzz:"制造行业",
          url:'/Make'
        },
        {
          hzz:"零售行业",
          url:'/ReTail'
        },
        {
          hzz:"汽车行业",
          url:'/AutoMobile'
        },
      ],
      hzDatas:[
        {
          hzz:"供应商管理系统",
          url:'/IndepenDent'
        },
        {
          hzz:"企业数字化平台",
          url:'/IndepenDent'
        },
      ],
      hzDataes:[
        {
          hzz:"定制化开发服务",
          url:'/CustoMization'
        },
        {
          hzz:"产品化解决方案服务",
          url:'/Product'
        },
        {
          hzz:"平台研发",
          url:'/PlatForm'
        },
        {
          hzz:"咨询服务",
          url:'/DigiTization'
        },
        {
          hzz:"运维服务",
          url:'/OperAtion'
        },
        {
          hzz:"外包服务",
          url:'/Epiboly'
        },
      ],
      newData: [
        {
          cname: "客户案例",
          url:'/Clien'
        },
        {
          cname: "合作伙伴",
          url:'/Partner'
        },
        {
          cname: "公司信息",
          url:'/information'
        }
      ],
      loopData: [
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
      ],
      foolDatas: [
        {
          lei: "金融行业",
          url: '/FinAnce'
        },
        {
          lei: "制造行业",
          url: 'Make'
        },
        {
          lei: "零售行业",
          url: '/ReTail'
        },
        {
          lei: "汽车行业",
          url:'/AutoMobile'
        },
      ],
      foolDatass: [
        {
          lei1: "供应商管理系统",
          url: '/IndepenDent'
        },
        {
          lei1: "企业经营数字化平台",
          url:'/IndepenDent'
        },
      ],
      foolDatasss: [
        {
          lei2: "定制化开发服务",
          url:'/CustoMization'
        },
        {
          lei2: "产品化解决方案服务",
          url:'/Product'
        },
        {
          lei2: "平台研发",
          url:'/PlatForm'
        },
        {
          lei2: "咨询服务",
          url:'/DigiTization'
        },
        {
          lei2: "运营服务",
          url:'/OperAtion'
        },
        {
          lei2: "外包服务",
          url:'/Epiboly'
        },
      ],
      foolDataes: [

      ],
      foolDataess: [

      ],
      lbData:[
        {
          imgg:require("../assets/img36.png")
        },
        {
          imgg:require("../assets/img36.png")
        },
        {
          imgg:require("../assets/img36.png")
        },
        {
          imgg:require("../assets/img36.png")
        },
        {
          imgg:require("../assets/img36.png")
        },
      ]
    }
  },
  methods:{
    din(item){
      console.log(item.url)
      window.open(item.url,'_blank')
    },
    handleCommand(command) {
      window.open(command,'_blank')
    },
    hang(){
      window.open('/Solution','_blank')
    },
    zi(){
      window.open('/IndepenDent','_blank')
    },
    dian1(){
      window.open('/FinAnce','_blank')
    },
    dian2(){
      window.open('/Make','_blank')
    },
    dian3(){
      window.open('/ReTail','_blank')
    },
    dian4(){
      window.open('/AutoMobile','_blank')
    },
    go(){
      window.open('/','_blank')
    },
    fu(){
      window.open('/scope','_blank')
    },
    hz(){
      window.open('/Partner','_blank')

    },
    xx(){
      window.open('/information','_blank')

    },
    cl(){
      window.open('/Clien','_blank')
    }
  }
}
</script>

<style scoped>
.autonomous{
  display: flex;
  flex-direction: column;
  margin: 0 auto 0;
  max-width: 1920px;
  min-width: 1500px;
  position: relative;
  width: 100%;
}
/*标题*/
.spreadhead {
  width: 100%;
  height: 122px;
  margin-bottom: 48px;
  /*background: red;*/
}
.spreadhead .subhead {
  display: inline-block;
  width: 100%;
  height: 24px;
  /*background: white;*/
  margin-top: 64px;
  margin-bottom: 12px;
}
.spreadhead .subhead .moit {
  display: flex;
  justify-content: space-between;
  width: 328px;
  height: 24px;
  margin: 0 auto;
  /*background: #124BFF;*/
  font-size: 24px;
  line-height: 24px;
}
.spreadhead .subhead .moit img {
  width: 64px;
  height: 18px;
  margin-top: 3px;
}
.spreadhead .subhead .moit span {
  display: block;
  height: 24px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  margin: 0 auto;
  overflow-wrap: break-word;
}
.spreadhead .details {
  display: block;
  /*display: inline-block;*/
  width: 476px;
  height: 22px;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  color: #666F8F;
  margin: auto;
}
/*第一层*/
.s-top{
  width: 100%;
  height: 526px;
  background-image: url("../assets/img38.png");
  background-repeat: no-repeat;
  background-size: cover;
}
/*第二层*/
.a-second{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 426px;
}
.a-second .group3{
  width: 100%;
  height: 66px;
  border-bottom: 1px solid #DDDDDD ;
}
.a-second .group3 ul{
  display: flex;
  justify-content: space-between;
  width: 199px;
  margin: 0 auto;
}
.a-second .group3 ul li{
  margin-top: 21px;
}
.a-second .group3 ul li p:first-child{
  width: 77px;
  height: 25px;
  font-size: 18px;
  color: black;
  margin-bottom: 17px;
}
.a-second .group3 ul li p:first-child.active{
  color: #124BFF;
}
.a-second .group3 ul li .underline{
  width: 56px;
  height: 3px;
  background: #124BFF;
  margin: 0 auto;
}
.a-second .group3-x{
  display: flex;
  flex-direction: column;
}
.a-second .group3-x .nei{
  width: 980px;
  height: 120px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202B4E;
  line-height: 38px;
  letter-spacing: 1px;
  margin: 0 auto;
}
/*第三层*/
.a-thirdy{
  width: 100%;
  height: 546px;
}
.a-thirdy img{
  display: block;
  width: 100%;
  height: 546px;
}
/*第四层*/
.a-four{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 491px;
}
.a-four .factory img{
  display: block;
  margin: 0 auto;
}

</style>
<style>
.el-dropdown {
  color: black;
  line-height: 60px;
  font-size: 16px;
  margin-right: 74px;
}

.el-button.is-round {
  margin-top: 16px;
  width: 88px;
  text-align: center;
  padding: 0px;
  height: 36px;
  /*background-color: rgba(18, 75, 255, 1);*/
}
.el-carousel__item img {
  width: 100%;
  line-height: 200px;
  margin: 0;
}
.el-carousel__arrow--left{
  border: 1px solid #124BFF;
}
.el-carousel__arrow--right{
  border: 1px solid #124BFF;
}
.el-carousel__arrow i{
  color: white;
}
</style>